<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Información de la Plantilla</h3>
        </b-col>
        <b-col
          cols="12"
          class="hp-profile-content-list mt-8 pb-8"
          v-if="checklist"
        >
          <ul>
            <li>
              <span class="hp-p1-body">Nombre</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{ checklist.name }}
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Tipo de Plantilla</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                {{
                  checklist.checklist_type
                    ? checklist.checklist_type.name
                    : "Sin Tipo"
                }}
              </span>
            </li>
            <li
              class="mt-18"
              v-if="checklist.project_type && checklist.project_type.length"
            >
              <span class="hp-p1-body">Tipos de proyectos</span>
              <span
                class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0"
              >
                <ul>
                  <li
                    v-for="project in checklist.project_type"
                    :key="project.id"
                  >
                    {{ project.name }}
                  </li>
                </ul>
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12" md="8" v-if="checklist">
      <h3>Descripción de la Plantilla</h3>
      <p class="hp-p1-body mb-0" v-html="checklist.description"></p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <!-- #Component Question -->
    <b-col cols="12" md="12">
      <questions></questions>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import Questions from "../../questions/Index.vue";
export default {
  components: {
    Questions,
  },
  computed: {
    ...mapGetters({
      checklist: "checklist/checklist",
    }),
  },
};
</script>
