<template>
  <b-modal
    size="lg"
    hide-footer
    id="modal-show-gallery"
    :title="`Galería de ${title}`"
  >
    <div class="mb-4" style="cursor: pointer">
      <b-button
        @click="openInputGallery"
        variant="outline-primary"
        class="mx-auto mb-2"
        id="pick-image-gallery"
        >Cargar imágenes de Ejemplo</b-button
      >
      <br />
      <small class="text-warning mt-3">
        <b class="px-1">IMPORTANTE:</b> Se pueden cargar varias imágenes
        simultáneamente
      </small>
    </div>
    <br />
    <section v-if="gallery.length">
      <b-row align-h="start">
        <b-col md="4" sm="12" v-for="item in gallery" :key="item.id">
          <b-img-lazy
            v-bind="mainPropsImage"
            class="w-100 mt-2"
            thumbnail
            fluid
            style="max-height: 150px"
            :src="item.image"
            alt="Image Gallery"
          >
          </b-img-lazy>
          <b-button
            @click="deleteImagePlaceholder(item.id)"
            size="sm"
            class="mt-2 mx-auto d-flex justify-content-center"
            v-b-tooltip.hover
            title="Eliminar imagen"
            variant="outline-danger"
          >
            <i style="font-size: 11.2px" class="iconly-Curved-Delete"></i>
            <small class="mx-2"> Eliminar </small>
          </b-button>
        </b-col>
      </b-row>
    </section>
    <p class="mt-2 text-center" v-else>Sin imágenes actualmente</p>
    <div class="text-center mx-auto mt-5" v-if="loading">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
      <h6 class="mt-16">Subiendo..</h6>
    </div>
    <input
      id="image-question"
      style="display: none"
      @change="setFile"
      type="file"
      multiple
      placeholder="Seleccionar imagen"
      accept=".jpg, .png, .jpeg"
    />
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ModalShowGalleryQuestion",
  data() {
    return {
      loading: false,
      mainPropsImage: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: 221,
        heigth: 136,
      },
      currentQuestionId: null,
      gallery: [],
      title: "",
      images: null,
    };
  },
  methods: {
    ...mapActions({
      uploadImageQuestion: "question/uploadImageQuestion",
      deletePlaceholderImage: "question/deletePlaceholderImage",
    }),
    setFile(e) {
      if (e.target.files.length) {
        this.images = e.target.files;
        this.handleSubmit();
      }
    },
    openInputGallery() {
      document.getElementById("image-question").click();
    },
    async handleSubmit() {
      this.loading = true;
      let response = null;
      let haveError = false;
      let self = this;
      for (const image of this.images) {
        response = await self.uploadImageQuestion({
          question_id: self.currentQuestionId,
          image: image,
        });
        if (response.status == 400 || response.status == 500) {
          haveError = true;
          return self.$bvToast.toast(`Imagen ${image.name}`, {
            title: `Error`,
            variant: "danger",
            solid: false,
          });
        }
      }
      this.loading = false;
      if (!haveError) {
        this.$bvToast.toast(
          `${
            this.images.length == 1 ? "Imagen cargada" : "Imagenes cargadas"
          } correctamente`,
          {
            title: `Excelente`,
            variant: "primary",
            solid: false,
          }
        );
        this.closeModal();
        this.$emit("imageUploaded");
      }
    },
    closeModal() {
      this.images = null;
      this.gallery = [];
      this.$bvModal.hide("modal-show-gallery");
    },
    async openModal(questionId, gallery, title) {
      this.currentQuestionId = questionId;
      this.gallery = gallery;
      this.title = title;
      this.$bvModal.show("modal-show-gallery");
    },
    deleteImagePlaceholder(id) {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar esta imagen de ejemplo?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deletePlaceholderImage(id);
            if (resp.status === 200) {
              this.$bvToast.toast(`Imagen eliminada correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
              this.filterImageDeleted(id);
              this.$emit("imageDeleted");
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
    filterImageDeleted(imageId) {
      this.gallery = this.gallery.filter((item) => item.id !== imageId);
    },
  },
};
</script>

<style>
.close-button {
  padding: 0px !important;
  margin-top: -20rem;
  border-radius: 100%;
}
</style>
