<template>
  <b-modal
    size="lg"
    hide-footer
    id="modal-show-managment-options"
    :title="`Gestión de opciones de ${title}`"
  >
    <h6 class="ml-5">Agregar nueva opción</h6>
    <div class="d-flex align-items-center">
      <b-form-input
        v-model="form.option"
        :disabled="currentOptionId != null"
        type="text"
        id="option-text"
        class="w-50 mx-5"
        placeholder="Ingresa el titulo de la opción"
      ></b-form-input>
      <b-button
        :disabled="currentOptionId !== null || !form.option"
        variant="outline-primary"
        class="mx-auto"
        @click="handleSubmit"
        >Agregar Opción</b-button
      >
    </div>
    <br />
    <section>
      <b-row align-h="center">
        <b-col cols="12">
          <datatable :showFilter="false" :items="options" :fields="fields">
            <template #name="{ item }">
              <span v-if="currentOptionId !== item.id">
                {{ item.name }}
              </span>
              <b-form-input
                v-else
                v-model="item.name"
                type="text"
                id="option-text-edit"
                placeholder="Ingresa el titulo de la opción"
              >
              </b-form-input>
            </template>
            <template #actions="{ item }">
              <div class="">
                <b-button
                  v-if="!currentOptionId"
                  size="sm"
                  v-b-tooltip.hover
                  title="Editar opción"
                  @click="currentOptionId = item.id"
                  variant="warning"
                  class="mx-3 btn-ghost"
                >
                  <i class="iconly-Curved-Edit"></i>
                </b-button>
                <b-button
                  v-if="currentOptionId && currentOptionId == item.id"
                  size="sm"
                  v-b-tooltip.hover
                  @click="handleSubmit"
                  title="Aceptar edición"
                  variant="warning"
                  class="btn-ghost"
                >
                  <i class="ri-check-fill"></i>
                </b-button>
                <b-button
                  v-if="currentOptionId && currentOptionId == item.id"
                  size="sm"
                  v-b-tooltip.hover
                  @click="currentOptionId = null"
                  title="Cancelar edición"
                  variant="danger"
                  class="btn-ghost mx-3"
                >
                  <i class="ri-close-fill"></i>
                </b-button>

                <b-button
                  @click="deleteOption(item.id, item.name)"
                  size="sm"
                  v-b-tooltip.hover
                  title="Eliminar opción"
                  variant="danger"
                  class="btn-ghost"
                >
                  <i class="ri-delete-bin-2-fill"></i>
                </b-button>
              </div>
            </template>
          </datatable>
        </b-col>
      </b-row>
    </section>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import Datatable from "@/view/components/common/datatable/Datatable.vue";

export default {
  name: "ModalShowManagmentOptions",
  components: {
    Datatable,
  },
  data() {
    return {
      loading: false,
      currentQuestionId: null,
      currentOptionId: null,
      options: [],
      title: "",
      form: {
        option: "",
      },
      fields: [
        {
          key: "name",
          label: "Titulo de la opción",
          sortable: true,
          class: "text-center",
        },
        { key: "actions", label: "Acciones" },
      ],
    };
  },
  methods: {
    ...mapActions({
      getQuestionOptions: "question/getQuestionOptions",
      updateOrCreateQuestionOption: "question/updateOrCreateQuestionOption",
      deleteQuestionOption: "question/deleteQuestionOption",
    }),
    resetForm() {
      this.form.option = "";
    },
    async handleSubmit() {
      if (!this.form.option && !this.currentOptionId) {
        return this.$bvToast.toast(`Imagen cargada correctamente`, {
          title: `Excelente`,
          variant: "primary",
          solid: false,
        });
      }
      this.loading = true;
      let response = await this.updateOrCreateQuestionOption({
        name: this.currentOptionId
          ? this.options.find((item) => item.id == this.currentOptionId).name
          : this.form.option,
        question: this.currentQuestionId,
        id: this.currentOptionId ? this.currentOptionId : undefined,
      });
      this.loading = false;
      if (response.status == 201 || response.status == 200) {
        this.$bvToast.toast(
          this.currentOptionId
            ? `Opción actualizada correctamente`
            : `Opción agregada correctamente`,
          {
            title: `Excelente`,
            variant: "primary",
            solid: false,
          }
        );
        this.currentOptionId = null;
        this.resetForm();
        this.options = await this.getQuestionOptionsFromQuestionId(
          this.currentQuestionId
        );
      }
    },
    closeModal() {
      this.currentQuestionId = null;
      this.resetForm();
      this.options = [];
      this.$bvModal.hide("modal-show-managment-options");
    },
    async openModal(id, title) {
      this.currentQuestionId = id;
      this.title = title;
      this.options = await this.getQuestionOptionsFromQuestionId(
        this.currentQuestionId
      );
      this.$bvModal.show("modal-show-managment-options");
    },
    async getQuestionOptionsFromQuestionId(questionId) {
      const options = await this.getQuestionOptions(questionId);
      return options.filter((item) => item.question == questionId);
    },
    deleteOption(id, name) {
      let self = this;
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: `¿Estas seguro de eliminar esta opción: ${name} ?`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteQuestionOption(id);
            if (resp.status === 204) {
              self.$bvToast.toast(`Opción eliminada correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
              self.options = await self.getQuestionOptionsFromQuestionId(id);
            }
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
};
</script>

<style>
.close-button {
  padding: 0px !important;
  margin-top: -20rem;
  border-radius: 100%;
}
</style>
