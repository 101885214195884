<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="item-container"
    :list="questions"
    @change="updateOrderQuestions"
  >
    <div class="item-group my-1 rounded" :key="el.id" v-for="el in questions">
      <div class="item">{{ `${el.order}. ${el.name}` }}</div>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex/dist/vuex.common.js";
export default {
  name: "DraggableOrderQuestions",
  components: {
    draggable,
  },
  props: {
    questions: {
      type: Array,
      required: true,
    },
    currentParendId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "child",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    ...mapActions({
      changeOrderQuestions: "question/changeOrderQuestions",
    }),
    async updateOrderQuestions(questions) {
      let payload = this.questions.map((question, index) => ({
        id: question.id,
        order: index + 1,
      }));

      const response = await this.changeOrderQuestions({
        questions: payload,
      });
      if (response.status == 200) {
        this.$bvToast.toast(
          `Orden de las preguntas actualizado correctamente`,
          {
            title: `Excelente`,
            variant: "primary",
            solid: false,
          }
        );
      }
      this.$emit("updateQuestions", payload);
    },
  },
};
</script>

<style>
.item-container {
  margin: 0;
}

.item {
  cursor: pointer;
  padding: 1rem;
  border: solid grey 0.3px;
  background-color: #fefefe;
  border-radius: 0.5rem;
}
.item-sub {
  margin: 0 0 0 1rem;
}
</style>
