<template>
  <b-card no-body style="border-radius: 0.5rem !important">
    <b-card-header header-tag="header" class="p-0 bg-transparent" role="tab">
      <div v-b-toggle="[toggleId]" class="p-32">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center mr-16">
            <p class="mb-0 font-weight-bold">
              {{ getNameQuestionWithOrder }}
            </p>
          </div>
          <section class="d-flex align-items-center">
            <b-button
              pill
              class="mx-2"
              v-if="type === typesQuestions.group"
              size="sm"
              @click="emitNewQuestion({ id }, TYPE_GROUP_ID)"
              variant="outline-primary"
              >Nuevo Subgrupo de Preguntas
              <i class="ri-add-circle-line lh-1 mx-2"></i>
            </b-button>
            <b-button
              pill
              @click="emitNewQuestion({ id }, TYPE_QUESTION_ID)"
              class="mx-2"
              v-if="type === typesQuestions.group"
              size="sm"
              variant="outline-primary"
              >Nueva Pregunta
              <i class="ri-add-circle-line lh-1 mx-2"></i>
            </b-button>
            <b-button
              v-if="
                type === typesQuestions.gallery ||
                type === typesQuestions.image ||
                type === typesQuestions.image_text
              "
              v-b-tooltip.hover
              title="Imagenes"
              @click="emitShowGallery(id, name, placeholders)"
              variant="outline-primary"
              class="mx-2 p-0"
              size="sm"
            >
              <i class="ri-image-line lh-1 mx-2" style="font-size: 15px"></i>
            </b-button>
            <b-button
              v-if="!countAnswers"
              v-b-tooltip.hover
              :title="`Editar ${
                type === typesQuestions.group ? 'Grupo' : 'Pregunta'
              }`"
              @click="
                emitEditQuestion(
                  parent,
                  { id, name, description, typeId },
                  type === typesQuestions.group
                    ? TYPE_GROUP_ID
                    : TYPE_QUESTION_ID
                )
              "
              variant="outline-warning"
              class="mx-2 p-0"
              size="sm"
            >
              <i class="ri-edit-line lh-1 mx-2" style="font-size: 15px"></i>
            </b-button>
            <b-button
              v-if="typeId === QUESTION_TYPES.select_answer"
              v-b-tooltip.hover
              :disabled="countAnswers >= 1"
              :title="
                countAnswers
                  ? 'Esta pregunta ya no se puede modificar por que posee respuestas'
                  : 'Gestionar opciones'
              "
              @click="emitShowManagmentOptions(id, name)"
              variant="outline-primary"
              class="mx-2 p-0"
              size="sm"
            >
              <i class="ri-list-check-2 lh-1 mx-2" style="font-size: 15px"></i>
            </b-button>
            <b-button
              v-if="!childs.length || type !== typesQuestions.group"
              v-b-tooltip.hover
              title="Eliminar"
              @click="emitDeleteQuestion(id, getNameQuestionWithOrder)"
              variant="outline-danger"
              class="mx-2 p-0"
              size="sm"
            >
              <i
                class="ri-delete-bin-line lh-1 mx-2"
                style="font-size: 15px"
              ></i>
            </b-button>
            <b-button
              v-if="childs.length"
              v-b-tooltip.hover
              title="Cambiar orden de preguntas"
              @click="emitChangeOrder(id, childs, name)"
              variant="outline-warning"
              class="mx-2 p-0"
              size="sm"
            >
              <i
                class="ri-drag-drop-line lh-1 mx-2"
                style="font-size: 15px"
              ></i>
            </b-button>
            <i
              v-if="childs.length"
              class="ri-arrow-right-s-line arrow-item hp-transition text-black-60"
              style="font-size: 20px"
            ></i>
          </section>
        </div>
        <small
          class="text-justify d-flex align-items-center"
          v-if="description && type !== typesQuestions.group"
        >
          <i
            class="ri-question-line mr-1 text-warning"
            style="font-size: 15px"
          ></i>
          <span class="font-weight-bold">Tipo de Pregunta:</span
          ><span class="mx-1">{{ type }}</span>
        </small>

        <small
          class="text-justify d-flex align-items-center mt-1"
          v-if="description && type !== typesQuestions.group"
        >
          <i
            class="ri-align-center-line mr-1 text-warning"
            style="font-size: 15px"
          ></i>
          <span class="mt-1 font-weight-bold">Descripción:</span
          ><span class="mx-1 mt-1">{{ description }}</span>
        </small>
      </div>
    </b-card-header>

    <b-collapse
      v-if="childs.length"
      :id="toggleId"
      :accordion="toggleId"
      role="tabpanel"
    >
      <b-card-body style="background-color: ghostwhite">
        <item-accordion-question
          @newQuestion="emitNewQuestion"
          @deleteQuestion="emitDeleteQuestion"
          @editQuestion="emitEditQuestion"
          @showGallery="emitShowGallery"
          @showManagmentOptions="emitShowManagmentOptions"
          @changeOrder="emitChangeOrder"
          v-for="questionSub in childs"
          :key="questionSub.id"
          :id="questionSub.id"
          :toggleId="`accordion-sub-${questionSub.id}`"
          :order="`${order}.${questionSub.order}`"
          :name="`${questionSub.name}`"
          :description="`${questionSub.description}`"
          :type="questionSub.question_type.name"
          :typeId="questionSub.question_type.id"
          :childs="questionSub.child"
          :parent="questionSub.parent"
          :countAnswers="questionSub.answers.length"
          :placeholders="questionSub.placeholders"
        >
        </item-accordion-question>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { TYPE_QUESTION, TYPE_GROUP } from "@/constants/question";
import { QUESTION_TYPES } from "@/constants/question";
export default {
  name: "ItemAccordionQuestion",
  props: {
    id: {
      required: true,
    },
    toggleId: {
      type: String,
      required: true,
    },
    order: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    typeId: {
      type: Number,
      required: true,
    },
    parent: {
      default: null,
    },
    description: {
      default: null,
    },
    countAnswers: {
      type: Number,
      default: 0,
    },
    childs: {
      type: Array,
      default: () => [],
    },
    placeholders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      TYPE_GROUP_ID: TYPE_GROUP,
      TYPE_QUESTION_ID: TYPE_QUESTION,
      QUESTION_TYPES,
      typesQuestions: {
        group: "Grupos/SubGrupos",
        gallery: "Galería de Imágenes",
        image: "Imagen Única",
        image_text: "Imágenes con Texto",
      },
    };
  },
  methods: {
    emitNewQuestion(parent, typeId) {
      this.$emit("newQuestion", parent, typeId);
    },
    emitDeleteQuestion(id, name) {
      this.$emit("deleteQuestion", id, name);
    },
    emitShowGallery(id, name, placeholders) {
      this.$emit("showGallery", id, name, placeholders);
    },
    emitShowManagmentOptions(id, name) {
      this.$emit("showManagmentOptions", id, name);
    },
    emitEditQuestion(parent, question, typeQuestion) {
      this.$emit("editQuestion", parent, question, typeQuestion);
    },
    emitChangeOrder(id, questions, name) {
      this.$emit("changeOrder", id, questions, name);
    },
  },
  computed: {
    getNameQuestionWithOrder() {
      return `${this.order}${!this.parent ? "." : ""} ${this.name}`;
    },
  },
};
</script>

<style></style>
