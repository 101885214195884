var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"hp-profile-menu py-24 px-0",staticStyle:{"flex":"0 0 240px"}},[(_vm.checklist)?_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"hp-profile-menu-header mt-16 mt-lg-0 text-center"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-inline-block position-relative"},[_c('div',{staticClass:"avatar-item d-flex align-items-center justify-content-center rounded-circle",staticStyle:{"width":"80px","height":"80px"}},[_c('img',{attrs:{"width":"75px","src":require('@/assets/img/app/checklists/checklist-icon.png')}})])])]),_c('h6',{staticClass:"mt-24 mb-4"},[_vm._v(_vm._s(_vm.checklist.name))]),_c('a',{staticClass:"hp-p1-body"},[_vm._v(" "+_vm._s(_vm.checklist.checklist_type ? _vm.checklist.checklist_type.name : "Sin Tipo")+" ")])])]):_vm._e(),(_vm.checklist)?_c('div',{staticClass:"hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"},[_c('ul',[_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
            _vm.path == `/app/checklists/${_vm.checklist.id}/managment`
              ? ' active'
              : ''
          }`,attrs:{"to":{
            name: 'show-checklist-managment',
            params: {
              id: _vm.checklist.id,
            },
          }}},[_c('i',{staticClass:"iconly-Curved-Document mr-8"}),_c('span',[_vm._v("Administrar Plantilla")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center`,attrs:{"to":{
            name: 'templates',
          }}},[_c('i',{staticClass:"iconly-Curved-ArrowLeft mr-8"}),_c('span',[_vm._v("Volver")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1)])]):_vm._e(),_c('div',{staticClass:"hp-profile-menu-footer"},[_vm._v("Plantillas")])])
}
var staticRenderFns = []

export { render, staticRenderFns }