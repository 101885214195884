<template>
  <b-col class="hp-profile-menu py-24 px-0" style="flex: 0 0 240px">
    <div class="w-100" v-if="checklist">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class="avatar-item d-flex align-items-center justify-content-center rounded-circle"
              style="width: 80px; height: 80px"
            >
              <img
                width="75px"
                :src="require('@/assets/img/app/checklists/checklist-icon.png')"
              />
            </div>
          </div>
        </div>

        <h6 class="mt-24 mb-4">{{ checklist.name }}</h6>
        <a class="hp-p1-body">
          {{
            checklist.checklist_type
              ? checklist.checklist_type.name
              : "Sin Tipo"
          }}
        </a>
      </div>
    </div>

    <div
      class="hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"
      v-if="checklist"
    >
      <ul>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'show-checklist-managment',
              params: {
                id: checklist.id,
              },
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
              path == `/app/checklists/${checklist.id}/managment`
                ? ' active'
                : ''
            }`"
          >
            <i class="iconly-Curved-Document mr-8"></i>
            <span>Administrar Plantilla</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
        <li class="mt-4 mb-16">
          <b-link
            :to="{
              name: 'templates',
            }"
            :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center`"
          >
            <i class="iconly-Curved-ArrowLeft mr-8"></i>
            <span>Volver</span>

            <span
              class="hp-menu-item-line position-absolute h-100 bg-success hp-bg-dark-0"
              style="width: 2px"
            ></span>
          </b-link>
        </li>
      </ul>
    </div>
    <div class="hp-profile-menu-footer">Plantillas</div>
  </b-col>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      path: "",
    };
  },
  watch: {
    $route() {
      this.getRoute();
    },
  },
  methods: {
    ...mapActions({
      getChecklist: "checklist/getChecklist",
    }),
    getRoute() {
      this.path = this.$route.path;
    },
  },
  created() {
    this.getRoute();
  },
  computed: {
    ...mapGetters({
      checklist: "checklist/checklist",
    }),
  },
};
</script>
