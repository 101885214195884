<template>
  <b-modal
    size="lg"
    hide-footer
    id="modal-order-questions"
    :title="`Ordenar preguntas de ${currentParendId ? 'Grupo:' : ''} ${title}`"
  >
    <section>
      <b-row align-h="start">
        <b-col md="12" sm="12">
          <draggable-order-questions
            @updateQuestions="updateOrderQuestions"
            :questions="questions"
            :currentParendId="currentParendId"
          />
        </b-col>
      </b-row>
      <p class="text-center my-3">
        Para cambiar el orden de las preguntas, arrastra la pregunta hasta el
        lugar deseado.
      </p>
    </section>
    <div class="text-center mx-auto mt-5" v-if="loading">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
      <h6 class="mt-16">Actualizando..</h6>
    </div>
    <div class="d-flex justify-content-end flex-wrap mt-5">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import DraggableOrderQuestions from "./DraggableOrderQuestions.vue";

export default {
  components: { DraggableOrderQuestions },
  name: "ModalOrderQuestions",
  data() {
    return {
      loading: false,
      title: "",
      questions: [],
      currentParendId: null,
    };
  },
  methods: {
    ...mapActions({}),
    async handleSubmit() {
      this.loading = true;

      this.loading = false;
    },
    closeModal() {
      this.images = null;
      this.questions = [];
      this.$bvModal.hide("modal-order-questions");
    },
    async openModal(id, questions, title) {
      this.currentParendId = id;
      this.questions = questions;
      this.title = title;
      this.$bvModal.show("modal-order-questions");
    },
    updateOrderQuestions(questions) {
      this.questions.forEach((questionCurrent) => {
        let questionNewOrder = questions.find(
          (question) => question.id == questionCurrent.id
        );
        questionCurrent.order = questionNewOrder.order;
      });
    },
  },
};
</script>

<style>
.close-button {
  padding: 0px !important;
  margin-top: -20rem;
  border-radius: 100%;
}
</style>
