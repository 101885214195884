<template>
  <div v-if="checklist">
    <div class="d-flex align-items-center justify-content-between mb-4 px-16">
      <h3>Preguntas</h3>
    </div>
    <div class="accordion mt-16 px-16" role="tablist" v-if="questions.length">
      <item-accordion-question
        @deleteQuestion="deleteQuestion"
        @newQuestion="openModalNewQuestion"
        @showGallery="openModalShowGallery"
        @showManagmentOptions="openModalShowManagmentOptions"
        @editQuestion="opemModalEditQuestion"
        @changeOrder="openModalQuestionOrder"
        v-for="question in questions"
        :key="question.id"
        :id="question.id"
        :toggleId="`accordion-${question.id}`"
        :name="`${question.name}`"
        :description="`${question.description}`"
        :order="`${question.order}`"
        :type="question.question_type.name"
        :typeId="question.question_type.id"
        :childs="question.child"
        :parent="question.parent"
        :countAnswers="question.answers.length"
        :placeholders="question.placeholders"
      >
      </item-accordion-question>
    </div>
    <p class="hp-p1-body mb-0 text-center mt-4" v-else>
      No Hay Preguntas en Este Momento para Esta Plantilla
    </p>
    <b-row class="mt-3 px-16">
      <b-col cols="12" md="4" sm="12">
        <b-button
          @click="openModalNewQuestion(null, TYPE_GROUP)"
          block
          variant="outline-primary"
          class="mt-16"
          >Nuevo Grupo de Preguntas
          <i class="ri-add-circle-line lh-1 mx-2" style="font-size: 24px"></i>
        </b-button>
      </b-col>
      <b-col cols="12" md="4" sm="12">
        <b-button
          @click="openModalNewQuestion(null, TYPE_QUESTION)"
          block
          variant="outline-primary"
          class="mt-16"
          >Nueva Pregunta
          <i class="ri-add-circle-line lh-1 mx-2" style="font-size: 24px"></i>
        </b-button>
      </b-col>
      <b-col cols="12" md="4" sm="12">
        <b-button
          :disabled="!questions.length"
          @click="openModalQuestionOrder(null, questions, checklist.name)"
          variant="outline-warning"
          class="mt-16"
          >Cambiar orden de preguntas
          <i class="ri-drag-drop-line lh-1 mx-2" style="font-size: 24px"></i>
        </b-button>
      </b-col>
    </b-row>
    <update-or-create-question
      @success="
        getQuestions({
          checklist_id: $route.params.id,
        })
      "
      ref="modalQuestion"
    ></update-or-create-question>
    <modal-order-questions
      @success="
        getQuestions({
          checklist_id: $route.params.id,
        })
      "
      ref="modalQuestionOrder"
    ></modal-order-questions>
    <modal-show-gallery-question
      @imageUploaded="
        getQuestions({
          checklist_id: $route.params.id,
        })
      "
      @imageDeleted="
        getQuestions({
          checklist_id: $route.params.id,
        })
      "
      ref="modalGalleryQuestion"
    ></modal-show-gallery-question>
    <modal-show-managment-options ref="modalManagmentOptions">
    </modal-show-managment-options>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TYPE_QUESTION, TYPE_GROUP } from "@/constants/question";
import UpdateOrCreateQuestion from "./updateOrCreateQuestion.vue";
import ModalShowGalleryQuestion from "./components/ModalShowGalleryQuestion.vue";
import ItemAccordionQuestion from "./components/ItemAccordionQuestion.vue";
import ModalShowManagmentOptions from "./components/ModalShowManagmentOptions.vue";
import ModalOrderQuestions from "./components/ModalOrderQuestions.vue";
export default {
  data() {
    return {
      TYPE_GROUP,
      TYPE_QUESTION,
    };
  },
  mounted() {
    this.getQuestions({
      checklist_id: this.$route.params.id,
    });
  },
  components: {
    ItemAccordionQuestion,
    UpdateOrCreateQuestion,
    ModalShowGalleryQuestion,
    ModalShowManagmentOptions,
    ModalOrderQuestions,
  },
  methods: {
    ...mapActions({
      sendDeleteQuestion: "question/deleteQuestion",
      getQuestions: "question/getQuestions",
    }),
    openModalNewQuestion(parentQuestion = null, typeQuestion = null) {
      this.$refs.modalQuestion.openModal(parentQuestion, typeQuestion);
    },
    openModalQuestionOrder(id, questions, title) {
      this.$refs.modalQuestionOrder.openModal(id, questions, title);
    },
    opemModalEditQuestion(parentQuestion, question, typeQuestion) {
      this.$refs.modalQuestion.openModal(
        parentQuestion,
        typeQuestion,
        question
      );
    },
    openModalShowGallery(id, name, placeholders) {
      this.$refs.modalGalleryQuestion.openModal(id, placeholders, name);
    },
    openModalShowManagmentOptions(id, name) {
      this.$refs.modalManagmentOptions.openModal(id, name);
    },

    deleteQuestion(id, name) {
      this.$bvModal
        .msgBoxConfirm(name, {
          title:
            "¿Estas seguro de eliminar este item?. Esta acción no se puede revertir",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",

          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeleteQuestion(id);
            if (resp.status == 200) {
              this.$bvToast.toast(`Item Eliminado Correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
            }
            this.getQuestions({
              checklist_id: this.$route.params.id,
            });
          }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  computed: {
    ...mapGetters({
      questions: "question/questions",
      checklist: "checklist/checklist",
    }),
  },
};
</script>
